import * as React from "react";

import Helmet from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/app.scss";
import Layout from "../components/layout";
import { OneStepContainer } from "../components/one-step-container/OneStepContainer";

// markup
const IndexPage = ({ location }) => {
  const stepWidth = 450;

  return (
    <Layout>
      <Helmet>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.contentWindow.js"
          integrity="sha512-cJ7aOLpXbec1Km9craM6xL6UOdlWf9etIz7f+cwQv2tuarLm3PLb3dv3ZqIK++SE4ui+EE0nWqKB0dOaAOv9gQ=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        ></script>
      </Helmet>
      <div className="w-full">
        <OneStepContainer 
          lang={'en'} 
          stepWidth={stepWidth} 
        />
      </div>
    </Layout>
  );
};

export default IndexPage;
